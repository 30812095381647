import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import trackEvent from 'gatsby-plugin-simple-analytics';

import Layout from 'src/components/layout';
import { forSize } from 'src/style-utils';
import logo from 'src/assets/logo.png';
import DownloadIcon from 'src/assets/download.svg';
import { H2, Text, ActionButton } from 'src/components/base';

const ThanksSection = styled.main`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  height: 100vh;

  padding: 120px;

  ${forSize.phoneOnly`
    padding: 20px;
  `};
`;

const ThanksSubHeading = styled(H2)`
  margin-top: 12px;
  text-align: center;
`;

const ThanksText = styled(Text)`
  margin-bottom: 42px;
  text-align: center;

  ${forSize.phoneOnly`
    margin-bottom: 20px;
  `}
`;

const Logo = styled.img`
  width: 128px;
  height: 128px;

  ${forSize.phoneOnly`
    width: 80px;
    height: 80px;
  `};
`;

export default function ThanksPage({ data }) {
  return (
    <Layout title="Thanks for joining!" withFooter={false} withHeader={false}>
      <ThanksSection>
        <Logo src={logo} />
        <ThanksSubHeading>Thanks for joining MonoFocus Beta!</ThanksSubHeading>
        <ThanksText>
          You can download the app now, but we’ve also sent a copy to your email.
        </ThanksText>
        <ActionButton
          tag="a"
          href="https://updates.monofocus.app/MonoFocus.latest.dmg"
          text="Download MonoFocus Beta"
          onClick={() => trackEvent('click_download')}
          icon={<DownloadIcon />}
        />
        <Text secondary small>
          {data.allAppcastRelease.edges[0].node.version}, macOS{' '}
          {data.allAppcastRelease.edges[0].node.minimumSystemVersion}+
        </Text>
      </ThanksSection>
    </Layout>
  );
}

export const query = graphql`
  query ThankYouQuery {
    allAppcastRelease {
      edges {
        node {
          version
          minimumSystemVersion
        }
      }
    }
  }
`;
