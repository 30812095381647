import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { forSize, colors } from 'src/style-utils';
import classNames from 'classnames';

export const Section = styled.section`
  position: relative;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px 24px;
`;

export const H1 = styled.h1`
  margin: 0 0 8px 0;
  color: ${(props) => props.color || 'inherit'};

  font-weight: 700;
  font-size: 26px;
  line-height: 32px;

  ${forSize.tabletPortraitUp`
    font-size: 40px;
    line-height: 40px;
  `};

  ${forSize.tabletLandscapeUp`
    font-size: 52px;
    line-height: 80px;
  `}
`;

export const H2 = ({ className, children, ...props }) => (
  <h2
    className={classNames(
      className,
      'font-semibold text-3xl leading-loose tabletLandscape:text-4xl'
    )}
    {...props}
  >
    {children}
  </h2>
);

export const H3 = styled.h2`
  margin: 0;
  color: ${(props) => props.color || 'inherit'};

  font-weight: normal;
  font-size: 18px;
  line-height: 24px;

  ${forSize.tabletLandscapeUp`
    font-size: 24px;
    line-height: 32px;
  `};
`;

export const Text = styled.p`
  margin: 4px 0 12px 0;

  color: ${(props) => props.color || 'inherit'};
  opacity: ${(props) => (props.secondary ? '0.6' : '1.0')};

  font-size: ${(props) => (props.small ? '14px' : '16px')};
  line-height: ${(props) => (props.small ? '16px' : '24px')};

  ${forSize.tabletLandscapeUp`
    font-size: ${(props) => (props.small ? '16px' : '20px')};
    line-height: ${(props) => (props.small ? '24px' : '32px')};
  `};
`;

export const ActionButtonContainer = styled(({ tag, children, ...props }) =>
  React.createElement(tag, props, children)
)`
  display: flex;
  width: 100%;

  justify-content: center;
  align-items: center;
  padding: 0 24px;

  font-size: 20px;
  font-weight: 500;
  text-decoration: none;
  padding: 16px 8px;
  line-height: 24px;

  color: #ffffff;
  background: ${colors.brand};

  border-radius: 6px;
  box-shadow: 0px 8px 32px rgba(250, 181, 49, 0.24);

  transition: 0.2s ease-out;

  &:hover {
    transform: scale(1.01) translateY(-2px);
    box-shadow: 0px 8px 24px rgba(250, 181, 49, 0.34);
  }

  ${forSize.tabletLandscapeUp`
    display: inline-flex;
    width: auto;
    padding: 16px 32px;
    justify-content: space-between;
  `};

  ${forSize.phoneOnly`
    font-size: 18px;
  `};

  svg {
    color: #ffffff;
    opacity: 0.55;
  }
`;

const ActionButtonText = styled.span`
  padding-right: 12px;
`;

export const ActionButton = ({ tag = Link, text, icon, ...props }) => (
  <ActionButtonContainer tag={tag} {...props}>
    <ActionButtonText>{text}</ActionButtonText>
    {icon}
  </ActionButtonContainer>
);
